var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('div',{staticClass:"text-h5 d-flex justify-center"},[_vm._v(" Ваш баланс "+_vm._s(_vm.formatPrice(_vm.userBalance))+" ")])]),_c('v-expansion-panel-content',[_vm._l((_vm.balanceRecords),function(item){return _c('div',{key:("balance-record-" + (item._id)),staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.description)),_c('v-spacer'),_c('div',{staticClass:"text-h5 green--text"},[_vm._v(" "+_vm._s(_vm.formatPrice(item.amount))+" ")])],1)}),(_vm.spent > 0)?_c('div',{staticClass:"d-flex"},[_vm._v(" На товары потрачено"),_c('v-spacer'),_c('div',{staticClass:"text-h5 red--text"},[_vm._v(_vm._s(_vm.formatPrice(_vm.spent)))])],1):_vm._e()],2)],1)],1)],1)],1),_c('v-row',_vm._l((_vm.displayProducts),function(product){return _c('v-col',{key:("product-card-" + (product._id)),attrs:{"cols":12,"xl":"4","lg":"4","md":"6","sm":"12","xs":"12"}},[_c('v-card',{style:(Object.assign({}, (_vm.productPurchasesByProductIDs[product._id]
            ? {
                backgroundColor: '#FAD099',
              }
            : {})))},[_c('div',{staticClass:"pa-4",staticStyle:{"background-color":"white"}},[_c('v-img',{staticStyle:{"background-color":"white"},attrs:{"height":"300","src":product.image,"contain":""}})],1),_c('v-card-title',{staticClass:"align-start",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(product.title))]),_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(product.description)+" ")]),_c('v-divider'),_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Цена")]),_c('v-spacer'),_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formatPrice(product.price)))])],1),(product.remaining > 0)?_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Осталось")]),_c('v-spacer'),_vm._v(" "+_vm._s(product.remaining)+" шт. ")],1):_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Этот подарок уже разобрали")])]),(
              _vm.productPurchasesByProductIDs[product._id] &&
              _vm.productPurchasesByProductIDs[product._id].quantity
            )?_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Вы приобрели")]),_c('v-spacer'),_c('strong',[_vm._v(_vm._s(_vm.productPurchasesByProductIDs[product._id].quantity)+" шт.")])],1):_vm._e()],1),_c('v-card-actions',[(product.price <= _vm.userBalance && product.remaining > 0)?[(
                _vm.productPurchasesByProductIDs[product._id] == null &&
                product.price <= _vm.userBalance
              )?_c('v-btn',{attrs:{"text":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(product, 1)}}},[_vm._v("Приобрести")]):_vm._e(),(
                _vm.productPurchasesByProductIDs[product._id] != null &&
                product.price <= _vm.userBalance &&
                ((product.maxPurchaseQuantity != null &&
                  _vm.productPurchasesByProductIDs[product._id].quantity <
                    product.maxPurchaseQuantity) ||
                  (product.maxPurchaseQuantity == null &&
                    _vm.productPurchasesByProductIDs[product._id].quantity > 0))
              )?_c('v-btn',{attrs:{"text":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(
                  product,
                  _vm.productPurchasesByProductIDs[product._id].quantity + 1
                )}}},[_vm._v("Приобрести еще 1 шт.")]):_vm._e()]:_vm._e(),_c('v-spacer'),(
              _vm.productPurchasesByProductIDs[product._id] &&
              _vm.productPurchasesByProductIDs[product._id].quantity
            )?_c('v-btn',{attrs:{"text":"","color":"secondary","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(product, 0)}}},[_vm._v("Отменить")]):_vm._e()],2)],1),_c('v-snackbar',{attrs:{"color":"#E1F5FE","app":"","centered":"","timeout":"1500","elevation":"1"},model:{value:(_vm.showApiMessage),callback:function ($$v) {_vm.showApiMessage=$$v},expression:"showApiMessage"}},[_c('div',{staticClass:"text-h5 black--text"},[_vm._v(_vm._s(_vm.apiMessage))])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }