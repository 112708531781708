<template>
  <div>
    <v-row>
      <v-col
        :cols="12"
        v-for="(item, index) in puzzles"
        xl="4"
        lg="4"
        md="4"
        sm="6"
        xs="12"
        :key="`puzzle-item-${index}`"
      >
        <v-card :elevation="4">
          <v-img
            :src="item.image"
            :aspect-ratio="16 / 9"
            style="cursor: pointer"
            @click="onPuzzleSelect(item)"
          />
          <v-card-title>{{ item.title }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog fullscreen :value="isModalOpen">
      <v-card v-if="selectedPuzzle != null">
        <v-card-actions>
          <h3>{{ selectedPuzzle.title }}</h3>
          <v-spacer />
          <v-btn color="primary" @click="isModalOpen = false">Закрыть</v-btn>
        </v-card-actions>
        <div
          style="background-color: white; padding: 16px"
          :style="{
            height: `${puzzleParentHeight}px`,
            width: `${puzzleParentWidth}px`,
          }"
        >
          <div
            v-if="selectedPuzzle.completed"
            :style="{
              height: `${puzzleParentHeight - 16 * 2}px`,
              width: `${puzzleParentWidth - 16 * 2}px`,
            }"
            style="position: relative"
          >
            <v-img
              :src="selectedPuzzle.image"
              style="position: absolute"
              :height="puzzleParentHeight - 16 * 2"
              :width="puzzleParentWidth - 16 * 2"
            />
            <div
              style="
                z-index: 2;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 16px;
                background-color: rgba(0, 0, 0, 0.75);
                color: white;
              "
              v-text="selectedPuzzle.text"
            ></div>
          </div>
          <div
            ref="puzzle"
            :style="{
              height: `${puzzleHeight}px`,
              width: `${puzzleWidth}px`,
              display: selectedPuzzle.completed ? 'none' : '',
            }"
          ></div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import headbreaker from "headbreaker";
import _ from "lodash";

import img from "./games/3.png";
// import img from './games/2.jpg';

export default {
  props: ["item-id", "params"],
  data() {
    return {
      img,
      isModalOpen: false,
      selectedPuzzle: null,
      puzzleParentHeight: 0,
      puzzleParentWidth: 0,
      puzzleHeight: 0,
      puzzleWidth: 0,
      topMenuHeight: 52,
      horizontalCount: 6,
      verticalCount: 6,
      isValid: false,
      storagePrefix: "puzzle",
    };
  },
  mounted() {
    this.puzzleParentHeight = window.innerHeight - this.topMenuHeight;
    this.puzzleParentWidth = window.innerWidth;
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.puzzleParentHeight = window.innerHeight - this.topMenuHeight;
      this.puzzleParentWidth = window.innerWidth;
    },
    onPuzzleSelect(puzzle) {
      this.isModalOpen = true;
      if (this.selectedPuzzle !== puzzle) {
        this.selectedPuzzle = puzzle;
        if (!puzzle.completed) {
          this.drawPuzzle();
        }
      }
    },
    async drawPuzzle() {
      this.isValid = false;
      let xul = new Image();
      const pieceSize = 100;
      this.puzzleParentHeight = window.innerHeight - this.topMenuHeight;
      this.puzzleParentWidth = window.innerWidth;
      this.puzzleHeight = this.puzzleParentHeight - 16 * 2;
      this.puzzleWidth = this.puzzleParentWidth - 16 * 2;
      await this.$nextTick();
      xul.src = this.selectedPuzzle.image;
      xul.onload = () => {
        const autogen = new headbreaker.Canvas(this.$refs.puzzle, {
          // width: 800,
          // height: 600,
          width: this.puzzleWidth,
          // width: this.selectedPuzzle.width,
          height: this.puzzleHeight,
          // height: this.selectedPuzzle.height,
          // pieceSize: Math.min(
          //   (this.puzzleWidth / this.horizontalCount) * 0.8,
          //   (this.puzzleHeight / this.verticalCount) * 0.8
          // ),
          // pieceSize: {
          //   x: this.selectedPuzzle.width / this.horizontalCount,
          //   y: this.selectedPuzzle.height / this.verticalCount,
          // },
          // pieceSize: {
          //   x: 200,
          //   y: 120,
          // },
          pieceSize: pieceSize,
          proximity: 20,
          borderFill: 10,
          strokeWidth: 1.5,
          lineSoftness: 0.18,
          image: xul,
          painter: new headbreaker.painters.Konva(),

          preventOffstageDrag: false,
          // fixed: true,
        });

        autogen.adjustImagesToPuzzleHeight();
        // autogen.autogenerate();
        autogen.autogenerate({
          horizontalPiecesCount: Math.floor(
            this.selectedPuzzle.width / pieceSize
          ),
          verticalPiecesCount: Math.floor(
            this.selectedPuzzle.height / pieceSize
          ),
        });

        autogen.attachSolvedValidator();
        autogen.onValid(() => {
          const storageKey = `${this.storagePrefix}-${this.selectedPuzzle.id}`;
          window.localStorage.setItem(storageKey, "completed");
          this.selectedPuzzle.completed = true;
          this.isValid = true;
        });

        autogen.shuffle();
        // autogen.scale((this.puzzleWidth / this.selectedPuzzle.width) * 0.75);
        // autogen.scale(0.75);
        autogen.draw();
      };
    },
  },
  computed: {
    puzzles() {
      const storage = window.localStorage;
      return _.map(_.get(this.params, "puzzles"), (item) => {
        return {
          ...item,
          completed:
            storage.getItem(`${this.storagePrefix}-${item.id}`) === "completed",
        };
      });
    },
  },
};
</script>
