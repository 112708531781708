var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',_vm._l((_vm.puzzles),function(item,index){return _c('v-col',{key:("puzzle-item-" + index),attrs:{"cols":12,"xl":"4","lg":"4","md":"4","sm":"6","xs":"12"}},[_c('v-card',{attrs:{"elevation":4}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":item.image,"aspect-ratio":16 / 9},on:{"click":function($event){return _vm.onPuzzleSelect(item)}}}),_c('v-card-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c('v-dialog',{attrs:{"fullscreen":"","value":_vm.isModalOpen}},[(_vm.selectedPuzzle != null)?_c('v-card',[_c('v-card-actions',[_c('h3',[_vm._v(_vm._s(_vm.selectedPuzzle.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isModalOpen = false}}},[_vm._v("Закрыть")])],1),_c('div',{staticStyle:{"background-color":"white","padding":"16px"},style:({
          height: (_vm.puzzleParentHeight + "px"),
          width: (_vm.puzzleParentWidth + "px"),
        })},[(_vm.selectedPuzzle.completed)?_c('div',{staticStyle:{"position":"relative"},style:({
            height: ((_vm.puzzleParentHeight - 16 * 2) + "px"),
            width: ((_vm.puzzleParentWidth - 16 * 2) + "px"),
          })},[_c('v-img',{staticStyle:{"position":"absolute"},attrs:{"src":_vm.selectedPuzzle.image,"height":_vm.puzzleParentHeight - 16 * 2,"width":_vm.puzzleParentWidth - 16 * 2}}),_c('div',{staticStyle:{"z-index":"2","position":"absolute","bottom":"0","left":"0","padding":"16px","background-color":"rgba(0, 0, 0, 0.75)","color":"white"},domProps:{"textContent":_vm._s(_vm.selectedPuzzle.text)}})],1):_vm._e(),_c('div',{ref:"puzzle",style:({
            height: (_vm.puzzleHeight + "px"),
            width: (_vm.puzzleWidth + "px"),
            display: _vm.selectedPuzzle.completed ? 'none' : '',
          })})])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }